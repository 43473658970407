import { FC, useContext, useState } from "react";
import Select from "react-select";
import appStyles from "../../App.module.css";

import appConstants, {
  additionalDestinations,
  customPackageDescriptions,
  formatAmount,
  formatDate,
  selectControlStyling,
} from "../../config/app-constants";
import { ISingleTripQuoteContext, ISingleTripQuoteData } from "../../config/interfaces";
import PackageDisplayName from "../common/PackageDisplayName";

import Popup from "../common/Popup";
import SvgIcon from "../common/SvgIcon";
import RentalVehicleDatePicker from "../Popups/RentalVehicleDatePicker";
import { SingleTripQuoteContext } from "../TripQuote";

interface PackageSelectorProps {
  pkg: any;
}

const PackageSelector: FC<PackageSelectorProps> = (props: PackageSelectorProps) => {
  const { pkg } = props;

  const { quoteData, setQuoteData, packageAmount } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;

  const [expanded, setExpanded] = useState<boolean>(false);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const isRequired: any = pkg?.validations?.find((elem: any) => {
    return elem?.product_package_validation_type?.name === "Is Required";
  });

  const onCBValueChange = (event: any) => {
    const _quoteData: ISingleTripQuoteData = { ...quoteData };

    if (event.target.checked) {
      _quoteData.packages = _quoteData.packages || {};

      _quoteData.packages[pkg.alias] = {
        package: pkg,
        level: pkg.levels[0],
        defaultLevel: null,
      };
    } else {
      delete _quoteData.packages[pkg.alias];
    }
    setQuoteData(_quoteData);
  };

  const onSelectValueChange = (newLevel: any) => {
    const _quoteData: ISingleTripQuoteData = { ...quoteData };
    if (newLevel.id) {
      _quoteData.packages = _quoteData.packages || {};
      _quoteData.packages[pkg.alias] = {
        package: pkg,
        level: newLevel,
        defaultLevel: pkg.levels[0], // setting the default level as the selected level for base package but for rest the first level as default level
      };
    } else {
      delete _quoteData.packages[pkg.alias];
    }

    setQuoteData(_quoteData);
  };

  const isSumAssured = pkg?.levels.find((_level: any) => _level.type === "$");

  const showpackagesAmount = packageAmount?.rates?.find(
    (_amount: any) => pkg.name === _amount.name
  );

  return pkg ? (
    <div
      className={`${appStyles.collapseBlock}
      ${
        isRequired && pkg?.levels?.length <= 1
          ? appStyles.collapseBlockPackageIncluded
          : quoteData?.packages[pkg?.alias] // trip interuption package
          ? appStyles.collapseBlockPackageAdded
          : ""
      }
      `}
    >
      <div className={appStyles.collapseHeader}>
        <div
          className={appStyles.collapseHeaderTitle}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <SvgIcon icon='question-mark-icon' />
          <p className={appStyles.colorPrimary}>
            <PackageDisplayName packageName={pkg?.name} />
            <br />
            {formatAmount(
              showpackagesAmount?.amount || 0,
              quoteData?.host_country?.code || "AU",
              quoteData?.currency?.value || "AUD"
            )}
          </p>
        </div>

        {!isRequired || pkg?.levels?.length > 1 ? (
          <div className={appStyles.collapseHeaderPrice}>
            <h6 className={`${appStyles.helperText}`}>
              {pkg?.levels?.length <= 1 ? (
                <div className={appStyles.switchWrapper}>
                  <input
                    type='checkbox'
                    value={pkg?.alias}
                    checked={quoteData?.packages[pkg?.alias] ? true : false}
                    disabled={
                      quoteData?.destinations.some(
                        (item) => item.name === additionalDestinations.aus_water_cruising.name
                      ) && pkg?.alias === appConstants.defaultPackageForWaterCruising
                        ? true
                        : false
                    }
                    onChange={onCBValueChange}
                  />
                  <span className={appStyles.switchLabel}></span>
                </div>
              ) : (
                <>
                  {isSumAssured && <p className={appStyles.helperText}>Sum Insured</p>}
                  <Select
                    value={quoteData?.packages[pkg?.alias]?.level || ""}
                    placeholder={"Not included"}
                    name={pkg?.alias}
                    options={pkg?.levels || []}
                    classNames={{
                      control: selectControlStyling,
                    }}
                    onChange={onSelectValueChange}
                    getOptionLabel={(option: any) => `${option["level"]}`}
                  />
                  {quoteData?.packages[pkg.alias]?.package?.alias ===
                    "rental_vehicle_damage_package" &&
                  pkg?.alias === "rental_vehicle_damage_package" ? (
                    <span
                      onClick={() => {
                        setShowDatePicker(true);
                      }}
                    >
                      <p className={`${appStyles.dateWrap} ${appStyles.helperText}`}>
                        {`${formatDate(
                          quoteData?.rental_vehicle_start_Date,
                          "short"
                        )} -${formatDate(quoteData?.rental_vehicle_end_Date, "short")}`}
                      </p>
                    </span>
                  ) : null}
                </>
              )}
            </h6>
          </div>
        ) : (
          <></>
        )}
        {showDatePicker && (
          <Popup
            onClose={() => {
              setShowDatePicker(false);
            }}
            title={"Enter your rental dates for Rental Vehicle Damage cover"}
            children={
              <RentalVehicleDatePicker
                onClose={() => {
                  setShowDatePicker(false);
                }}
              />
            }
          />
        )}
      </div>

      {expanded && (
        <div className={appStyles.collapseBody}>
          <p className={appStyles.helperText}>{customPackageDescriptions[pkg.alias] || ""}</p>
        </div>
      )}
    </div>
  ) : null;
};

export default PackageSelector;
