import appConstants from "../config/app-constants";

export default class QuoteApi {
  static getHeaders = (checkSandbox = false) => {
    let headers: any = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (checkSandbox && appConstants.isSandBoxMode) {
      headers["is-sandbox-mode"] = "1";
    }

    return headers;
  };

  static getHostCountries = async (productId: string) => {
    const response = await fetch(`${appConstants.apiUrl}/product/${productId}/host-countries`, {
      headers: this.getHeaders(),
    });
    return response.json();
  };

  static getSTDestinations = async (productId: string) => {
    const response = await fetch(
      `${appConstants.apiUrl}/affiliate/product/${productId}/destination-countries`,
      {
        headers: this.getHeaders(),
      }
    );
    return response.json();
  };

  static getHostCountryPackages = async (
    productId: string,
    countryCode: number | null,
    stateCode: number | null
  ) => {
    const response = await fetch(
      `${appConstants.apiUrl}/product/${productId}/host-country/${countryCode}/host-country-state/${stateCode}/packages`,
      {
        headers: this.getHeaders(),
      }
    );
    return response.json();
  };

  static getQuickQuote = async (data: any) => {
    const response = await fetch(`${appConstants.apiUrl}/quote`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: this.getHeaders(true),
    });
    return response.json();
  };

  static createOrder = async (data: any) => {
    const response = await fetch(`${appConstants.apiUrl}/orders/condensed`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: this.getHeaders(true),
    });
    return response.json();
  };

  static getPaymentIntent = async (orderId: string) => {
    const response = await fetch(`${appConstants.apiUrl}/orders/${orderId}/payment/intent`, {
      headers: this.getHeaders(true),
    });
    return response.json();
  };

  static getPackageAmounts = async (data: any) => {
    const response = await fetch(`${appConstants.apiUrl}/quote/details/discounts`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: this.getHeaders(true),
    });
    return response.json();
  };
}
