import { FC, useContext, useState } from "react";
import DatePicker from "react-date-picker";
import appStyles from "../../App.module.css";
import appConstants from "../../config/app-constants";
import { ISingleTripQuoteContext } from "../../config/interfaces";
import SvgIcon from "../common/SvgIcon";
import { SingleTripQuoteContext } from "../TripQuote";

interface PopupProps {
  onClose?: any;
}

const RentalVehicleDatePicker: FC<PopupProps> = (props: PopupProps) => {
  const { onClose } = props;

  const { quoteData, setQuoteData } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;
  const [startDate, setStartDate] = useState(quoteData.rental_vehicle_start_Date);
  const [endDate, setEndDate] = useState(quoteData.rental_vehicle_end_Date);

  return (
    <div className={appStyles.battlefacePluginModalContentData}>
      <div className={appStyles.formRow}>
        <div className={appStyles.formBlock}>
          <div className={`${appStyles.formField} `}>
            <label className={appStyles.helperText}>Start Date</label>

            <DatePicker
              calendarClassName={`${appStyles.bfpDatePickerCalendar} ${appStyles.bfModalDatePickerCalendar}`}
              className={`${appStyles.bfpDatePicker} ${appStyles.bfpDatePickerCalendarStartDate}`}
              value={startDate}
              format={appConstants.datePickerFormat}
              minDate={quoteData?.start_date ? new Date(quoteData.start_date) : new Date()}
              maxDate={quoteData?.end_date ? new Date(quoteData.end_date) : new Date()}
              dayPlaceholder='dd'
              monthPlaceholder='mm'
              yearPlaceholder='yyyy'
              id='start_date'
              onChange={(value: any) => {
                setStartDate(value);
                if (!endDate || value > endDate) {
                  setEndDate(value);
                }
              }}
              calendarIcon={() => {
                return <SvgIcon icon='calendar-icon' />;
              }}
              clearIcon={() => {
                return <SvgIcon icon='clear-icon' />;
              }}
            />
          </div>
        </div>
        <div className={appStyles.formBlock}>
          <div className={`${appStyles.formField} `}>
            <label className={appStyles.helperText}>End Date</label>

            <DatePicker
              calendarClassName={`${appStyles.bfpDatePickerCalendar} ${appStyles.bfModalDatePickerCalendar}`}
              className={`${appStyles.bfpDatePicker} ${appStyles.bfpDatePickerCalendarStartDate}`}
              value={endDate}
              format={appConstants.datePickerFormat}
              minDate={quoteData?.start_date ? new Date(quoteData.start_date) : new Date()}
              maxDate={quoteData?.end_date ? new Date(quoteData.end_date) : new Date()}
              dayPlaceholder='dd'
              monthPlaceholder='mm'
              yearPlaceholder='yyyy'
              id='start_date'
              onChange={(value: any) => {
                setEndDate(value);
                if (!startDate || value < startDate) {
                  setStartDate(value);
                }
              }}
              calendarIcon={() => {
                return <SvgIcon icon='calendar-icon' />;
              }}
              clearIcon={() => {
                return <SvgIcon icon='clear-icon' />;
              }}
            />
          </div>
        </div>
      </div>
      <div className={`${appStyles.formRow} ${appStyles.bfBtnWrap}`}>
        <button
          onClick={() => {
            let _quoteData = { ...quoteData };
            _quoteData.rental_vehicle_start_Date = startDate;
            _quoteData.rental_vehicle_end_Date = endDate;
            setQuoteData(_quoteData);
            onClose();
          }}
          type='button'
          className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfButtonSecondary} `}
        >
          Save
        </button>
        <button
          onClick={onClose}
          type='button'
          className={`${appStyles.bfButton} ${appStyles.bfButtonSecondary} ${appStyles.bfButtonOutline}  ${appStyles.bfbuttonIconBefore} `}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RentalVehicleDatePicker;
